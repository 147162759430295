import React, { ReactNode } from 'react'

import { Container, Time } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { useLorem } from '../../lorem'
import placeholderImage1 from '../../assets/placeholder-1.jpg'
import placeholderImage2 from '../../assets/placeholder-2.jpg'

interface ImageCardProps {
  category?: string
  children: ReactNode
  imageSrc?: string
}

const ImageCard = ({ category, children, imageSrc }: ImageCardProps) => (
  <article className="image-card">
    <div
      className="image-card-thumbnail"
      style={imageSrc ? { backgroundImage: `url(${imageSrc})` } : undefined}
    />
    <p className="small image-card-time mb-0">
      <Time date={new Date()} />
    </p>
    <div className="image-card-content">
      <p className="lead image-card-text mb-0">
        <LinkMock className="image-card-link">{children}</LinkMock>
      </p>
      <p className="small image-card-category mb-0">{category}</p>
    </div>
  </article>
)

type ImageCardExampleProps = {
  category?: string
}

export const ImageCardExample = ({
  category = 'Blogi'
}: ImageCardExampleProps) => {
  const lorem1 = useLorem({ words: 5 })
  const lorem2 = useLorem({ words: 5 })
  const lorem3 = useLorem({ sentences: 3 })
  const lorem4 = useLorem({ words: 5 })
  const lorem5 = useLorem({ sentences: 3 })
  const lorem6 = useLorem({ words: 5 })

  return (
    <ul className="grid-list grid-list-md-3 grid-list-gap-lg">
      <li className="grid-list-item">
        <ImageCard
          category={category}
          imageSrc={placeholderImage1}
        >
          {lorem1}
        </ImageCard>
      </li>
      <li className="grid-list-item">
        <ImageCard
          category={category}
          imageSrc={placeholderImage2}
        >
          {lorem2}
        </ImageCard>
      </li>
      <li className="grid-list-item">
        <ImageCard category={category}>{lorem3}</ImageCard>
      </li>
      <li className="grid-list-item">
        <ImageCard
          category={category}
          imageSrc={placeholderImage2}
        >
          {lorem4}
        </ImageCard>
      </li>
      <li className="grid-list-item">
        <ImageCard category={category}>{lorem5}</ImageCard>
      </li>
      <li className="grid-list-item">
        <ImageCard
          category={category}
          imageSrc={placeholderImage1}
        >
          {lorem6}
        </ImageCard>
      </li>
    </ul>
  )
}

const Page = () => (
  <Content
    heading="ImageCard"
    subHeading="Verkkopalvelu"
  >
    <Playground
      format="html"
      enableOverflow
      WrapperComponent={Container}
    >
      <ImageCardExample />
    </Playground>
  </Content>
)

export default Page
