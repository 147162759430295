import React, { useEffect } from 'react'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'

import { ImageCardExample } from './imagecard'

export const Example1 = () => {
  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeLinkNav()
  }, [])

  return (
    <>
      <ul
        className="link-nav-list mb-lg"
        role="tablist"
      >
        <li
          className="link-nav-item"
          role="presentation"
        >
          <a
            aria-controls="lorem"
            aria-selected="true"
            className="link-nav-link active"
            data-toggle="tab"
            href="#lorem"
            id="link-nav-item-1"
            role="tab"
          >
            Lorem
          </a>
        </li>
        <li
          className="link-nav-item"
          role="presentation"
        >
          <a
            aria-controls="ipsum"
            aria-selected="false"
            className="link-nav-link"
            data-toggle="tab"
            href="#ipsum"
            id="link-nav-item-2"
            role="tab"
          >
            Ipsum
          </a>
        </li>
        <li
          className="link-nav-item"
          role="presentation"
        >
          <a
            aria-controls="dolor"
            aria-selected="false"
            className="link-nav-link"
            data-toggle="tab"
            href="#dolor"
            id="link-nav-item-3"
            role="tab"
          >
            Dolor
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          aria-labelledby="recent-recommended-tab"
          className="tab-pane active"
          id="lorem"
          role="tabpanel"
        >
          <ImageCardExample category="Uutiset" />
        </div>
        <div
          aria-labelledby="recent-news-tab"
          className="tab-pane"
          id="ipsum"
          role="tabpanel"
        >
          <ImageCardExample category="Uutiset" />
        </div>
        <div
          aria-labelledby="recent-blogs-tab"
          className="tab-pane"
          id="dolor"
          role="tabpanel"
        >
          <ImageCardExample category="Blogit" />
        </div>
      </div>
    </>
  )
}

export const Example2 = () => {
  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeLinkNav()
  }, [])

  return (
    <>
      <ul
        className="link-nav-list link-nav-list-dark mb-lg"
        role="tablist"
      >
        <li
          className="link-nav-item"
          role="presentation"
        >
          <a
            aria-controls="suosittelemme"
            aria-selected="true"
            className="link-nav-link active"
            data-toggle="tab"
            href="#suosittelemme"
            id="recent-recommended-tab"
            role="tab"
          >
            Suosittelemme
          </a>
        </li>
        <li
          className="link-nav-item"
          role="presentation"
        >
          <a
            aria-controls="uutiset"
            aria-selected="false"
            className="link-nav-link"
            data-toggle="tab"
            href="#uutiset"
            id="recent-news-tab"
            role="tab"
          >
            Uutiset
          </a>
        </li>
        <li
          className="link-nav-item"
          role="presentation"
        >
          <a
            aria-controls="blogit"
            aria-selected="false"
            className="link-nav-link"
            data-toggle="tab"
            href="#blogit"
            id="recent-blogs-tab"
            role="tab"
          >
            Blogit
          </a>
        </li>
        <li
          className="link-nav-item"
          role="presentation"
        >
          <a
            aria-controls="videot"
            aria-selected="false"
            className="link-nav-link"
            data-toggle="tab"
            href="#videot"
            id="recent-videos-tab"
            role="tab"
          >
            Videot
          </a>
        </li>
        <li
          className="link-nav-item"
          role="presentation"
        >
          <a
            aria-controls="kayttajien-tarinat"
            aria-selected="false"
            className="link-nav-link"
            data-toggle="tab"
            href="#kayttajien-tarinat"
            id="recent-stories-tab"
            role="tab"
          >
            Käyttäjien tarinat
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          aria-labelledby="recent-recommended-tab"
          className="tab-pane active"
          id="suosittelemme"
          role="tabpanel"
        >
          <ImageCardExample category="Uutiset" />
        </div>
        <div
          aria-labelledby="recent-news-tab"
          className="tab-pane"
          id="uutiset"
          role="tabpanel"
        >
          <ImageCardExample category="Uutiset" />
        </div>
        <div
          aria-labelledby="recent-blogs-tab"
          className="tab-pane"
          id="blogit"
          role="tabpanel"
        >
          <ImageCardExample category="Blogit" />
        </div>
        <div
          aria-labelledby="recent-videos-tab"
          className="tab-pane"
          id="videot"
          role="tabpanel"
        >
          <ImageCardExample category="Videot" />
        </div>
        <div
          aria-labelledby="recent-stories-tab"
          className="tab-pane"
          id="kayttajien-tarinat"
          role="tabpanel"
        >
          <ImageCardExample category="Käyttäjien tarinat" />
        </div>
      </div>
    </>
  )
}

const Page = () => (
  <Content
    heading="LinkNav"
    subHeading="Verkkopalvelu"
  >
    <Playground format="html">
      <Example1 />
    </Playground>
    <Playground
      format="html"
      isDark
    >
      <Example2 />
    </Playground>
  </Content>
)

export default Page
